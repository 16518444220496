'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.tasks.factory:TaskTypeManager

 # @description

###
angular
  .module 'mundoAdmin.tasks'
  .factory 'TaskTypeManager', [
    'MundoDefaultManager'
    'TenantManager'
    (
      MundoDefaultManager
      TenantManager
    )->
      TaskTypeManagerBase = new MundoDefaultManager()
      TaskTypeManagerBase.setAutoTenant()
      TaskTypeManagerBase.setUrl('lpa/tasks/types')
      TaskTypeManagerBase.setNewObject(['code', 'label'])
      TaskTypeManagerBase.setUpdateObject(['code', 'label'])

      TaskTypeManagerBase.setSearchFields(['code', 'label', 'tenant.label'])

      TaskTypeManagerBase.getNewForm = ->
        [
          key: 'code'
          name: 'code'
          type: 'input'
          templateOptions:
            label: 'code'
            placeholder: 'code'
            required: true
        ,
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ]
      TaskTypeManagerBase.getEditForm = (data)->
        [
          key: 'code'
          name: 'code'
          type: 'input'
          defaultValue: data.code
          templateOptions:
            label: 'code'
            placeholder: 'code'
            required: true
        ,
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ]
      TaskTypeManagerBase.getListSchema = ->
        [
          key: 'code'
          title: 'code'
          sort: 'code'
        ,
          key: 'label'
          title: 'label'
          sort: 'label'
        ,
  #        key: 'tenant.label'
  #        title: 'tenant'
  #        sort: 'tenant.label'
        ]

      TaskTypeManagerBase.editPermission = 'manage all MundoMosaLpaDispatchingBundle:TaskType entities'

      TaskTypeManagerBase
    ]
